import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz-trans/client';

import FormRadioButton from '../../FormRadioButton';

import IconLavaLamp from '../icons/IconLavaLamp';
import IconCouch from '../icons/IconCouch';
import IconSparkles from '../icons/IconSparkles';
import IconSofa from '../icons/IconSofa';

import styles from '../SpottyCat.module.scss';

const Budget = ({ budget, onBudgetSelected }) => (
  <>
    <p className={styles['spotty-cat__title']}>
      {trans('spotty-cat-screen-title-budget')}
    </p>
    <div className={styles['spotty-cat__options']}>
      <FormRadioButton
        value="lessThan600"
        customIcon={() => <IconLavaLamp />}
        selected={budget === 'lessThan600'}
        onClick={() => onBudgetSelected('lessThan600')}
        showFeedback
      >
        <div className={styles['spotty-cat__option']}>
          <p className={styles['spotty-cat__option-subtitle']}>
            {trans('spotty-cat-screen-less-600-option')}
          </p>
        </div>
      </FormRadioButton>

      <FormRadioButton
        value="lessThan900"
        customIcon={() => <IconSofa />}
        selected={budget === 'lessThan900'}
        onClick={() => onBudgetSelected('lessThan900')}
        showFeedback
      >
        <div className={styles['spotty-cat__option']}>
          <p className={styles['spotty-cat__option-subtitle']}>
            {trans('spotty-cat-screen-less-900-option')}
          </p>
        </div>
      </FormRadioButton>
      <FormRadioButton
        value="lessThan1200"
        customIcon={() => <IconCouch />}
        selected={budget === 'lessThan1200'}
        onClick={() => onBudgetSelected('lessThan1200')}
        showFeedback
      >
        <div className={styles['spotty-cat__option']}>
          <p className={styles['spotty-cat__option-subtitle']}>
            {trans('spotty-cat-screen-less-1200-option')}
          </p>
        </div>
      </FormRadioButton>
      <FormRadioButton
        value="moreThan1200"
        customIcon={() => <IconSparkles />}
        selected={budget === 'moreThan1200'}
        onClick={() => onBudgetSelected('moreThan1200')}
        showFeedback
      >
        <div className={styles['spotty-cat__option']}>
          <p className={styles['spotty-cat__option-subtitle']}>
            {trans('spotty-cat-screen-more-1200-option')}
          </p>
        </div>
      </FormRadioButton>
    </div>
  </>
);

Budget.propTypes = {
  budget: PropTypes.string.isRequired,
  onBudgetSelected: PropTypes.func.isRequired
};

export default Budget;
