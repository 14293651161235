/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz-trans/client';

import * as yup from 'yup';

import FormSelect from '../FormSelect';
import FormInput from '../FormInput';
import FormDate from '../FormDate';
import Button from '../Button';

import FormPhoneInput from '../FormPhoneInput';

import FormError from './FormError';

import styles from './FormProvider.module.scss';

const FormContext = createContext('FormContext');

export const useFormValues = () => {
  const { values } = useContext(FormContext);
  return { values };
};

const FormProvider = ({
  dataTest,
  onSubmit,
  onError,
  schema,
  fieldsClassName,
  children,
  disabled,
  initialValues,
  isLoading,
  onInputChange
}) => {
  const [dirtyInputs, setDirtyInputs] = useState([]);
  const [values, setValues] = useState(initialValues);
  const [errors, setFormErrors] = useState({});

  const validateField = async ({ name, value }) => {
    try {
      await yup.reach(schema, name).validate(value);
      setFormErrors({
        ...errors,
        [name]: undefined
      });
    } catch (error) {
      const { message } = error;
      setFormErrors({
        ...errors,
        [name]: message
      });
    }
  };

  const handleInputChange = async ({ value, name }) => {
    if (!dirtyInputs.includes(name)) {
      setDirtyInputs([...dirtyInputs, name]);
    }

    setValues(prevState => ({
      ...prevState,
      [name]: value
    }));

    // if the field has an error, check if new value removes it
    if (errors[name]) {
      await validateField({ name, value });
    }
    onInputChange({ value, name });
  };

  const handleInputBlur = async ({ value, name }) => {
    if (!dirtyInputs.includes(name)) {
      return;
    }

    await validateField({ name, value });
    try {
      await yup.reach(schema, name).validate(value);
      setFormErrors({
        ...errors,
        [name]: undefined
      });
    } catch (error) {
      const { message } = error;
      setFormErrors({
        ...errors,
        [name]: message
      });
    }
  };

  const handleSubmit = async e => {
    const event = Object.assign({}, e);
    e.preventDefault();
    if (disabled) {
      return;
    }

    try {
      await schema.validate(values, { abortEarly: false });
      onSubmit(values, event);
    } catch (error) {
      const newErrors = {};
      if (error && error.inner) {
        error.inner.forEach(errorItem => {
          newErrors[errorItem.path] = errorItem.message;
        });
        setFormErrors(newErrors);
      }
      onError();
    }
  };

  const isFieldRequired = fieldName =>
    schema.fields[fieldName].spec.presence === 'required';

  const value = {
    errors,
    values,
    disabled,
    handleInputChange,
    handleInputBlur,
    handleSubmit,
    fieldsClassName,
    isLoading,
    isFieldRequired
  };

  return (
    <FormContext.Provider value={value}>
      <form data-test={dataTest} onSubmit={handleSubmit}>
        {typeof children === 'function' ? children({ values }) : children}
      </form>
    </FormContext.Provider>
  );
};

FormProvider.Input = ({
  name,
  id = name,
  innerRef,
  onBlurHandler,
  ...formInputProps
}) => {
  const {
    handleInputChange,
    handleInputBlur,
    errors,
    fieldsClassName,
    values,
    isFieldRequired
  } = useContext(FormContext);

  const handleBlur = (...args) => {
    handleInputBlur(...args);
    onBlurHandler();
  };

  return (
    <div className={fieldsClassName} key={id || name}>
      <FormInput
        {...formInputProps}
        value={values[name]}
        id={id}
        name={name}
        onChange={handleInputChange}
        onBlur={handleBlur}
        required={isFieldRequired(name)}
        innerRef={innerRef}
      />
      <FormError error={trans(errors[name])} />
    </div>
  );
};

FormProvider.Input.propTypes = {
  ...FormInput.propTypes,
  onBlurHandler: PropTypes.func
};

FormProvider.Input.defaultProps = {
  ...FormInput.defaultProps,
  onBlurHandler: () => {}
};

FormProvider.Date = ({ name, onChangeHandler, ...formDateProps }) => {
  const {
    handleInputChange,
    handleInputBlur,
    errors,
    fieldsClassName,
    values,
    isFieldRequired
  } = useContext(FormContext);

  const handleChange = args => {
    onChangeHandler(args);
    handleInputChange(args);
  };

  return (
    <div className={fieldsClassName} key={name}>
      <FormDate
        {...formDateProps}
        name={name}
        onChange={handleChange}
        onBlur={handleInputBlur}
        required={isFieldRequired(name)}
        value={values[name]}
      />
      <FormError error={trans(errors[name])} />
    </div>
  );
};

FormProvider.Date.propTypes = {
  ...FormDate.propTypes,
  onChangeHandler: PropTypes.func
};

FormProvider.Date.defaultProps = {
  ...FormDate.defaultProps,
  onChangeHandler: () => {}
};

FormProvider.PhoneInput = ({ id, name, title, required }) => {
  const {
    handleInputChange,
    handleInputBlur,
    errors,
    fieldsClassName,
    values
  } = useContext(FormContext);

  return (
    <div className={fieldsClassName}>
      <FormPhoneInput
        id={id}
        name={name}
        title={title}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        required={required}
        value={values[name]}
      />
      <FormError error={trans(errors[name])} />
    </div>
  );
};

FormProvider.PhoneInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired
};

FormProvider.Submit = ({ id, dataTest, children, color, onClick }) => {
  const { fieldsClassName, isLoading, disabled } = useContext(FormContext);

  return (
    <div className={fieldsClassName}>
      <Button
        id={id}
        disabled={disabled || isLoading}
        size="big-redesign"
        color={color}
        data-test={dataTest || 'form-submit'}
        type="submit"
        onClick={onClick}
      >
        {children}
      </Button>
    </div>
  );
};

FormProvider.Submit.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  dataTest: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func
};

FormProvider.Submit.defaultProps = {
  id: null,
  dataTest: '',
  color: 'primary',
  onClick: () => {}
};

FormProvider.Select = ({ name, ...formSelectProps }) => {
  const {
    handleInputChange,
    handleInputBlur,
    errors,
    fieldsClassName,
    values,
    isFieldRequired
  } = useContext(FormContext);

  return (
    <div className={fieldsClassName} key={name}>
      <FormSelect
        {...formSelectProps}
        name={name}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        required={isFieldRequired(name)}
        value={values[name]}
      />
      <FormError error={trans(errors[name])} />
    </div>
  );
};

FormProvider.Select.propTypes = FormSelect.propTypes;

FormProvider.Select.defaultProps = FormSelect.defaultProps;

FormProvider.Password = ({
  name,
  id = name,
  dataTest,
  title,
  placeholder,
  disabled,
  showPassword,
  onBlurHandler
}) => {
  const {
    handleInputChange,
    handleInputBlur,
    errors,
    fieldsClassName,
    values,
    isFieldRequired
  } = useContext(FormContext);
  const [displayPassword, setDisplayPassword] = useState(showPassword);

  const handleBlur = (...args) => {
    handleInputBlur(...args);
    onBlurHandler();
  };

  const handleTogglePassword = () => {
    setDisplayPassword(!displayPassword);
  };

  return (
    <div className={fieldsClassName} key={id || name}>
      <FormInput
        value={values[name]}
        id={id}
        dataTest={dataTest}
        title={title}
        name={name}
        type={displayPassword ? 'text' : 'password'}
        placeholder={placeholder}
        onChange={handleInputChange}
        onBlur={handleBlur}
        required={isFieldRequired(name)}
        disabled={disabled}
        renderSuffix={() => (
          <Button
            color="link"
            className={styles['form-provider__password-show']}
            onClick={handleTogglePassword}
            aria-controls={id}
            aria-expanded={displayPassword}
          >
            {displayPassword
              ? trans('form-password.hide')
              : trans('form-password.show')}
          </Button>
        )}
      />
      <FormError error={trans(errors[name])} />
    </div>
  );
};

FormProvider.Password.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  dataTest: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  showPassword: PropTypes.bool,
  onBlurHandler: PropTypes.func
};

FormProvider.Password.defaultProps = {
  id: undefined,
  dataTest: '',
  title: '',
  placeholder: '',
  disabled: false,
  showPassword: false,
  onBlurHandler: () => {}
};

FormProvider.propTypes = {
  dataTest: PropTypes.string,
  initialValues: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  onInputChange: PropTypes.func,
  schema: PropTypes.shape({
    validate: PropTypes.func,
    fields: PropTypes.shape({
      [PropTypes.string]: PropTypes.shape({})
    })
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  fieldsClassName: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool
};

FormProvider.defaultProps = {
  dataTest: 'form-provider',
  initialValues: {},
  onSubmit: () => {},
  onError: () => {},
  onInputChange: () => {},
  fieldsClassName: '',
  isLoading: false,
  disabled: false
};

export default FormProvider;
