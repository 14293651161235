import { useState } from 'react';
import PropTypes from 'prop-types';

import { useSoyuzLocales } from '@spotahome/soyuz/client';

import Metrics from '@spotahome/soyuz-tracking';

import { ExternalLinkProvider } from '../../../context/ExternalLink';
import Modal from '../../Modal';

import PropertyType from '../screens/PropertyType';
import MoveIn from '../screens/MoveIn';
import RentalType from '../screens/RentalType';
import Budget from '../screens/Budget';
import Email from '../screens/Email';
import City from '../screens/City';
import Success from '../screens/Success';

import {
  INTRODUCTION,
  PROPERTY_TYPE,
  MOVE_IN,
  RENTAL_TYPE,
  CITY,
  BUDGET,
  EMAIL,
  SUCCESS,
  TOTAL_STEPS,
  FORM_URL_OPTIONS
} from '../constants';

import {
  createSearchURLFromOptions,
  getFeaturedCitiesFormatted
} from '../utils';

import SpottyCatHeader from './SpottyCatHeader';
import SpottyCatIntro from './SpottyCatIntro';
import SpottyCatProgressBar from './SpottyCatProgressBar';

import styles from './SpottyCatModal.module.scss';
import SoyuzAnalytics from "@spotahome/soyuz-analytics";

const SpottyCatModal = ({
  step,
  isOpen,
  isUserLogged,
  user,
  cities,
  onClose,
  onPrevStep,
  onNextStep,
  onSuccess,
  onSignupWithoutPassword
}) => {
  const { current: locale } = useSoyuzLocales();
  const [propertyType, setPropertyType] = useState(null);
  const [moveIn, setMoveIn] = useState(null);
  const [rentalType, setRentalType] = useState(null);
  const [budget, setBudget] = useState(null);
  const [email, setEmail] = useState((user && user.email) || '');
  const [city, setCity] = useState('');

  const cityId = city && cities.find(c => c.cityName === city).id;

  const searchUrl = createSearchURLFromOptions(
    locale,
    [
      FORM_URL_OPTIONS.propertyType[propertyType],
      FORM_URL_OPTIONS.moveIn[moveIn],
      FORM_URL_OPTIONS.rentalType[rentalType],
      FORM_URL_OPTIONS.budget[budget]
    ],
    cityId
  );

  const handleCloseModal = () => {
    setPropertyType('');
    setMoveIn('');
    setRentalType('');
    setCity('');
    setEmail('');
    setBudget('');
    onClose();
  };

  const handleOptionSelected = optionValue => {
    switch (step) {
      case PROPERTY_TYPE:
        Metrics.ga.sendEvent('SpottyCat', 'FormPropertyType', optionValue);
        SoyuzAnalytics.sendGA4Event('SpottyCat', {field: 'FormPropertyType', value: optionValue});
        setPropertyType(optionValue);
        break;
      case MOVE_IN:
        Metrics.ga.sendEvent('SpottyCat', 'FormMoveIn', optionValue);
        SoyuzAnalytics.sendGA4Event('SpottyCat', {field: 'FormMoveIn', value: optionValue});
        setMoveIn(optionValue);
        break;
      case RENTAL_TYPE:
        Metrics.ga.sendEvent('SpottyCat', 'FormRentalType', optionValue);
        SoyuzAnalytics.sendGA4Event('SpottyCat', {field: 'FormRentalType', value: optionValue});
        setRentalType(optionValue);
        break;
      case CITY:
        const cityId = cities.find(c => c.cityName === optionValue).id;
        Metrics.ga.sendEvent(
          'SpottyCat',
          'FormCity',
          cityId
        );
        SoyuzAnalytics.sendGA4Event('SpottyCat', {field: 'FormCity', value: cityId});
        setCity(optionValue);
        break;
      case BUDGET:
        Metrics.ga.sendEvent('SpottyCat', 'FormBudget', optionValue);
        SoyuzAnalytics.sendGA4Event('SpottyCat', {field: 'FormBudget', value: optionValue});
        setBudget(optionValue);
        break;
      case EMAIL:
        setEmail(optionValue);
        break;
      default:
    }
    onNextStep();
  };

  return (
    <ExternalLinkProvider currentLocale={locale}>
      <Modal
        className={styles['spotty-cat-modal']}
        contentLabel="SpottyCatModal"
        isOpen={isOpen}
        onRequestClose={handleCloseModal}
        size="big"
      >
        {step >= PROPERTY_TYPE && (
          <SpottyCatHeader
            showBackButton={step >= MOVE_IN && step < SUCCESS}
            handleBackButton={onPrevStep}
            handleCloseButton={handleCloseModal}
          />
        )}

        <div className={styles['spotty-cat-modal__screen']}>
          {step === INTRODUCTION && <SpottyCatIntro onNextStep={onNextStep} />}
          {step === PROPERTY_TYPE && (
            <PropertyType
              propertyType={propertyType}
              onPropertyTypeSelected={handleOptionSelected}
            />
          )}
          {step === MOVE_IN && (
            <MoveIn onMoveInSelected={handleOptionSelected} moveIn={moveIn} />
          )}
          {step === RENTAL_TYPE && (
            <RentalType
              onRentalTypeSelected={handleOptionSelected}
              rentalType={rentalType}
            />
          )}
          {step === CITY && (
            <City
              city={city}
              cityList={cities}
              onCitySelected={handleOptionSelected}
              featuredCities={getFeaturedCitiesFormatted(cities)}
            />
          )}
          {step === BUDGET && (
            <Budget onBudgetSelected={handleOptionSelected} budget={budget} />
          )}
          {step === EMAIL && (
            <Email
              name={user && user.fullname}
              email={email}
              locale={locale}
              cityId={cityId}
              searchUrl={searchUrl}
              isUserLogged={isUserLogged}
              onSuccess={onSuccess}
              onEmailSelected={handleOptionSelected}
              onSignupWithoutPassword={onSignupWithoutPassword}
            />
          )}
          {step === SUCCESS && (
            <Success
              email={email}
              searchUrl={searchUrl}
              isContactUsShown={false} // Not used from anywhere
            />
          )}
        </div>

        {step > INTRODUCTION && (
          <SpottyCatProgressBar step={step} totalSteps={TOTAL_STEPS} />
        )}
      </Modal>
    </ExternalLinkProvider>
  );
};

SpottyCatModal.propTypes = {
  step: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isUserLogged: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    fullname: PropTypes.string
  }).isRequired,
  cities: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  onPrevStep: PropTypes.func.isRequired,
  onNextStep: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onSignupWithoutPassword: PropTypes.func.isRequired
};

export default SpottyCatModal;
