import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz-trans/client';

import Button from '../Button';

import styles from './Autocompleter.module.scss';

import AutocompleterItem from './AutocompleterItem';

const NoResultSuggestionText = ({
  searchedCity,
  onSubmitSuggestion = () => {}
}) => (
  <div>
    <span>{trans('autocompleter.no-results.title', searchedCity)}</span>{' '}
    <span role="button" tabIndex={0} className="autocompleter__no-result-cta">
      {trans('autocompleter.no-results.subtitle')}
    </span>
    <Button
      alt
      className={styles['autocompleter__no-result-button']}
      onClick={onSubmitSuggestion}
    >
      {trans('autocompleter.no-results.submit')}
    </Button>
  </div>
);

NoResultSuggestionText.propTypes = {
  searchedCity: PropTypes.string.isRequired,
  onSubmitSuggestion: PropTypes.func
};

const AutocompleterNoResult = ({
  value,
  otherCitySuggestionsEnabled = false,
  onSubmitSuggestion = () => {}
}) => (
  <AutocompleterItem
    noResult
    item={
      otherCitySuggestionsEnabled ? (
        <NoResultSuggestionText
          searchedCity={value}
          onSubmitSuggestion={onSubmitSuggestion}
        />
      ) : (
        trans('autocompleter.results.empty')
      )
    }
  />
);

AutocompleterNoResult.propTypes = {
  value: PropTypes.string.isRequired,
  otherCitySuggestionsEnabled: PropTypes.bool,
  onSubmitSuggestion: PropTypes.func
};

export default AutocompleterNoResult;
