import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz-trans/client';

import FormRadioButton from '../../FormRadioButton';

import IconRunner from '../icons/IconRunner';
import IconCoffee from '../icons/IconCoffee';

import styles from '../SpottyCat.module.scss';

const MoveIn = ({ onMoveInSelected, moveIn }) => (
  <>
    <p className={styles['spotty-cat__title']}>
      {trans('spotty-cat-screen-title-move-in')}
    </p>
    <div className={styles['spotty-cat__options']}>
      <FormRadioButton
        value="inARush"
        customIcon={() => <IconRunner />}
        selected={moveIn === 'inARush'}
        onClick={() => onMoveInSelected('inARush')}
        showFeedback
      >
        <div className={styles['spotty-cat__option']}>
          <p className={styles['spotty-cat__option-title']}>
            {trans('spotty-cat-screen-movein-rush-option')}
          </p>
          <p className={styles['spotty-cat__option-subtitle']}>
            {trans('spotty-cat-screen-movein-rush-description')}
          </p>
        </div>
      </FormRadioButton>

      <FormRadioButton
        value="chilled"
        customIcon={() => <IconCoffee />}
        selected={moveIn === 'chilled'}
        onClick={() => onMoveInSelected('chilled')}
        showFeedback
      >
        <div className={styles['spotty-cat__option']}>
          <p className={styles['spotty-cat__option-title']}>
            {trans('spotty-cat-screen-movein-chilled-option')}
          </p>
          <p className={styles['spotty-cat__option-subtitle']}>
            {trans('spotty-cat-screen-movein-chilled-description')}
          </p>
        </div>
      </FormRadioButton>
    </div>
  </>
);

MoveIn.propTypes = {
  onMoveInSelected: PropTypes.func.isRequired,
  moveIn: PropTypes.string.isRequired
};

export default MoveIn;
