import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import Metrics from '@spotahome/soyuz-tracking';
import { trans } from '@spotahome/soyuz-trans/client';

import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import CheckboxWithLabel from '../../CheckboxWithLabel';
import FormProvider from '../../FormProvider';

import { useHeaderLinks } from '../../../context/ExternalLink';

import styles from '../SpottyCat.module.scss';

const emailSchema = yup.object().shape({
  email: yup.string().ensure().required('common.form.required-field')
});

const Email = ({
  email = '',
  name = '',
  cityId,
  searchUrl = '',
  locale,
  isUserLogged,
  onSuccess,
  onEmailSelected,
  onSignupWithoutPassword = null
}) => {
  const { termsAndConditions, privacyPolicies } = useHeaderLinks();
  const [marketingOptIn, setMarketingOptIn] = useState(false);

  const isSignupWithoutPassFlow =
    !isUserLogged && typeof onSignupWithoutPassword === 'function';

  const handleValidationError = () => {
    Metrics.ga.sendEvent('SpottyCat', 'FormEmail', 'ValidationError');
    SoyuzAnalytics.sendGA4Event('SpottyCat', {
      field: 'FormEmail',
      value: 'ValidationError'
    });
  };

  const handleMarketingOptInSelect = () => {
    setMarketingOptIn(!marketingOptIn);
  };

  const handleSubmit = async values => {
    try {
      await onSuccess({
        eventName: 'spotty_cat_email',
        recipientEmail: values.email,
        data: {
          searchUrl,
          cityId,
          fullName: name,
          locale
        }
      });
      Metrics.ga.sendEvent('SpottyCat', 'FormEmail', 'EmailSent');
      SoyuzAnalytics.sendGA4Event('SpottyCat', {
        action: 'FormEmail',
        value: 'EmailSent'
      });

      if (isSignupWithoutPassFlow) {
        await onSignupWithoutPassword({
          email: values.email,
          locale,
          marketingOptIn
        });
        Metrics.ga.sendEvent('SpottyCat', 'FormEmail', 'SignupWithoutPassword');
        SoyuzAnalytics.sendGA4Event('SpottyCat', {
          action: 'FormEmail',
          value: 'SignupWithoutPassword'
        });
      }
    } catch (err) {
      Metrics.ga.sendEvent('SpottyCat', 'FormEmail', 'NetworkError');
      SoyuzAnalytics.sendGA4Event('SpottyCat', {
        action: 'FormEmail',
        value: 'NetworkError'
      });
    }

    onEmailSelected(values.email);
  };

  const handleStepForLoggedUsers = async () => {
    if (isUserLogged) {
      await handleSubmit({ email });
    }
  };

  useEffect(() => {
    handleStepForLoggedUsers();
  }, []);

  return isUserLogged ? null : (
    <FormProvider
      initialValues={{ email }}
      onSubmit={handleSubmit}
      onError={handleValidationError}
      schema={emailSchema}
      fieldsClassName={styles['spotty-cat__email']}
    >
      <p className={styles['spotty-cat__title']}>
        {trans('spotty-cat-screen-title-email')}
      </p>

      <FormProvider.Input
        type="email"
        name="email"
        title={trans('loginsignup.modal.email')}
        required
        autoFocus
        fullWidth
      />

      {isSignupWithoutPassFlow && (
        <>
          <CheckboxWithLabel
            className={styles['spotty-cat__marketing-opt-in']}
            checked={marketingOptIn}
            callback={handleMarketingOptInSelect}
            text={trans('opt-in-checkbox-text')}
            name="marketingOptIn"
            id="marketingOptIn"
          />
          <div
            className={styles['spotty-cat__terms-and-conditions']}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: trans(`authentication.signup.terms_and_conditions`, {
                terms: termsAndConditions,
                privacy: privacyPolicies
              })
            }}
          />
        </>
      )}

      <FormProvider.Submit>
        {isSignupWithoutPassFlow
          ? trans('loginsignup.modal.signup-form.submit')
          : trans('loginsignup.modal.continue')}
      </FormProvider.Submit>
    </FormProvider>
  );
};

Email.propTypes = {
  email: PropTypes.string,
  cityId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onEmailSelected: PropTypes.func.isRequired,
  onSignupWithoutPassword: PropTypes.func,
  locale: PropTypes.string.isRequired,
  name: PropTypes.string,
  searchUrl: PropTypes.string,
  isUserLogged: PropTypes.bool.isRequired
};

export default Email;
