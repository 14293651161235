import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz-trans/client';

import styles from '../SpottyCat.module.scss';

import Autocompleter from '../../Autocompleter';

import { getFormattedCities } from '../utils';

const City = ({ onCitySelected, featuredCities, cityList, city }) => (
  <>
    <p className={styles['spotty-cat__title']}>
      {trans('spotty-cat-screen-title-city-type')}
    </p>

    <div className={styles['spotty-cat__autocompleter']}>
      <Autocompleter
        minLength={1}
        initialValue={city}
        placeholder={trans('spotty-cat-screen-city-placeholder')}
        featuredResults={featuredCities}
        list={getFormattedCities(cityList)}
        onSelectedElement={onCitySelected}
        selectAllContentOnFocus={false}
      />
    </div>
  </>
);
City.propTypes = {
  onCitySelected: PropTypes.func.isRequired,
  cityList: PropTypes.arrayOf(PropTypes.string).isRequired,
  featuredCities: PropTypes.arrayOf(PropTypes.string).isRequired,
  city: PropTypes.string.isRequired
};

export default City;
