import PropTypes from 'prop-types';

import classNames from 'classnames/bind';
import { trans } from '@spotahome/soyuz-trans/client';

import styles from './FormSelect.module.scss';

const cx = classNames.bind(styles);

const PLACEHOLDER_VALUE = '';

const FormSelect = ({
  innerRef,
  name,
  id,
  title,
  value,
  disabled,
  options,
  required,
  topOptions,
  onChange,
  onBlur,
  onClick,
  hasError,
  placeholder,
  allowToSelectPlaceholder,
  dataTest,
  hideTitle
}) => {
  const selectClassnames = cx('form-select', {
    'form-select--with-error': hasError,
    'form-select--disabled': disabled,
    'form-select--option-selected': value !== PLACEHOLDER_VALUE,
    'form-select--hidden-title': hideTitle
  });

  const titleClassnames = cx('form-select__title', {
    'form-select__title--with-error': hasError
  });

  const chevronClassnames = cx('form-select__chevron', {
    'form-select__chevron--disabled': disabled,
    'form-select__chevron--hidden-title': hideTitle
  });

  const handleChange = event => {
    const { value: eventValue } = event.target;
    onChange({ value: eventValue, name });
  };

  return (
    <div className={styles['form-select__wrapper']}>
      {!hideTitle && (
        <span className={titleClassnames}>
          {title.toUpperCase()}
          {required ? '*' : null}
        </span>
      )}
      <select
        ref={innerRef}
        id={id}
        name={name}
        className={selectClassnames}
        data-test={dataTest}
        onChange={handleChange}
        onBlur={onBlur}
        onClick={onClick}
        required={required}
        disabled={disabled}
        value={value}
      >
        <option
          data-test={`${dataTest}-placeholder`}
          default
          disabled={!allowToSelectPlaceholder}
          value={PLACEHOLDER_VALUE}
        >
          {placeholder || trans('form-select.placeholder')}
        </option>
        {topOptions.map(({ text, value: optionValue, locoKey }) => (
          <option
            key={`top-option-${optionValue}`}
            data-test={`${dataTest}-${optionValue}`}
            value={optionValue}
          >
            {locoKey ? trans(locoKey) : text}
          </option>
        ))}
        {topOptions.length && <option disabled>──────────</option>}
        {options.map(({ text, value: optionValue, locoKey }) => (
          <option
            key={`option-${optionValue}`}
            data-test={`${dataTest}-${optionValue}`}
            value={optionValue}
          >
            {locoKey ? trans(locoKey) : text}
          </option>
        ))}
      </select>
      <span className={chevronClassnames} />
    </div>
  );
};

FormSelect.propTypes = {
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  allowToSelectPlaceholder: PropTypes.bool,
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      text: PropTypes.string,
      locoKey: PropTypes.string
    })
  ).isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  title: PropTypes.string,
  topOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string,
      locoKey: PropTypes.string
    })
  ),
  hideTitle: PropTypes.bool
};

FormSelect.defaultProps = {
  value: '',
  innerRef: null,
  allowToSelectPlaceholder: true,
  dataTest: 'form-select',
  disabled: false,
  hasError: false,
  onChange: () => {},
  onBlur: () => {},
  onClick: () => {},
  placeholder: '',
  required: false,
  topOptions: [],
  title: '',
  hideTitle: false
};

export default FormSelect;
